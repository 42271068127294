import { AxiosPromise } from 'axios';

import { NETWORK_ROOT_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { mapAPINetworkDataToNetwork, mapAPINetworlListToNetworkList } from './dataFormatter';
import { CreateNetworkPayload, CreateNetworkResponse, Network } from './types';

export const getNetworkListApi = (
  phaseList: string[] = [],
  deviceName = '',
): Promise<Network[]> => {
  const params = new URLSearchParams();
  phaseList.forEach((phase) => params.append('phases', phase));
  if (deviceName) {
    params.append('deviceName', deviceName);
  }
  return authAxios(
    {
      method: 'GET',
      url: NETWORK_ROOT_URL,
      params,
    },
    true,
    true,
  ).then((response) => mapAPINetworlListToNetworkList(response.data.items ?? []));
};

export const getNetworkDetailsApi = (name: string): Promise<Network> =>
  authAxios({ method: 'GET', url: `${NETWORK_ROOT_URL}${name}/` }, true, true).then((response) =>
    mapAPINetworkDataToNetwork(response.data),
  );

export const createNetworkResource = (
  data: CreateNetworkPayload,
): AxiosPromise<CreateNetworkResponse> =>
  authAxios({ method: 'POST', url: NETWORK_ROOT_URL, data }, true, true);

export const deleteNetworkApi = (name: string): AxiosPromise<null> =>
  authAxios(
    {
      method: 'DELETE',
      url: `${NETWORK_ROOT_URL}${name}/`,
    },
    true,
    true,
  );
