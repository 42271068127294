const { CONFIG = {} } = window;

export const { DISABLE_PODS_SSH } = CONFIG;
export const { EXECUTABLE_LIMITS, BROKER_RESOURCE_LIMITS, NATIVE_NETWORK_LIMITS } = CONFIG;
export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export const { SENTRY_URL, BANNER_CONFIG } = CONFIG;

export const SUPPORT_EMAIL = CONFIG.SUPPORT_EMAIL || 'support@rapyuta-robotics.com';
export const DOCS_ROOT_URL = CONFIG.DOCS_ROOT_URL || 'https://docs.rapyuta.io/';

export const DEVICE_MANAGER_ROOT_URL = `${CONFIG.DM_ENDPOINT}`;
export const DEVICE_MANAGER_KEYS_URL = `${CONFIG.DM_ENDPOINT}auth-keys/`;
export const DEVICE_MANAGER_API_BASE_URL = `${CONFIG.DM_ENDPOINT}devices/`;
export const DEVICE_MANAGER_ARCH_DEVICE_MAP_URL = `${CONFIG.DM_ENDPOINT}selection/query`;
export const DEVICE_MANAGER_BOOTSTRAP_URL = CONFIG.DM_BOOTSTRAP_ENDPOINT;
export const { WEBSSH_CONNECT_APPEND } = CONFIG;
export const COMMAND_API_URL = `${CONFIG.DM_ENDPOINT}cmd/`;
export const DEVICE_LABELS_URL = `${CONFIG.DM_ENDPOINT}labels/`;
export const DEVICE_CONFIG_VARIABLES_URL = `${CONFIG.DM_ENDPOINT}config_variables/`;
export const DEVICE_LOGS_ROOT_URL = `${DEVICE_MANAGER_ROOT_URL}logs/`;

export const CONFIGURATION_SERVER_API_BASE_URL = `${CONFIG.CORE_ENDPOINT}api/paramserver/`;

export const AUTH_ENDPOINT = CONFIG.RIP_FRONTEND_ENDPOINT;
export const LOGIN_ROOT_URL = `${CONFIG.RIP_FRONTEND_ENDPOINT}login/`;

export const CORE_ROOT_URL = CONFIG.CORE_ENDPOINT;
export const USER_DETAILS_URL = `${CORE_ROOT_URL}api/user/me/get`;
export const TOGGLE_USER_STATE_URL = `${CORE_ROOT_URL}api/user/toggleState`;
export const ORG_REGISTER_URL = `${CONFIG.CORE_ENDPOINT}api/organization/create`;
export const ORG_ROOT_URL = `${CORE_ROOT_URL}api/organization/`;
export const USER_GROUP_ROOT_URL = `${CORE_ROOT_URL}api/group`;

export const PROJECTS_ROOT_URL = `${CONFIG.V2_CORE_ENDPOINT}v2/projects/`;
export const SECRETS_ROOT_URL = `${CONFIG.V2_CORE_ENDPOINT}v2/secrets/`;

export const DISKS_API_BASE_URL = `${CONFIG.V2_CORE_ENDPOINT}v2/disks/`;

export const STATIC_ROUTES_ROOT_URL = `${CONFIG.V2_CORE_ENDPOINT}v2/staticroutes/`;
export const V2_CORE_ENDPOINT_URL = CONFIG.V2_CORE_ENDPOINT;
export const CATALOG_ROOT_URL = CONFIG.CATALOG_ENDPOINT;
export const NETWORK_ROOT_URL = `${CONFIG.V2_CORE_ENDPOINT}v2/networks/`;
export const BUILDS_ROOT_URL = `${CATALOG_ROOT_URL}build`;

export const PACKAGES_LIST_URL = `${CONFIG.CATALOG_ENDPOINT}v2/catalog`;
export const PACKAGES_LIST_URL_V2 = `${CONFIG.V2_CORE_ENDPOINT}v2/packages/`;
export const DEPLOYMENT_URL = `${CONFIG.V2_CORE_ENDPOINT}v2/deployments/`;
export const PACKAGES_DETAILS_URL = `${CONFIG.CATALOG_ENDPOINT}serviceclass/status`;
export const DEPLOYMENT_CREATE_URL = `${CONFIG.CATALOG_ENDPOINT}v2/service_instances/`;

export const COUNTRY_ROOT_URL = `${CORE_ROOT_URL}api/country`;

export const RCB_CORE_ENDPOINT = `${CONFIG.CORE_ENDPOINT}api/rcb/`;
export const PLANS_DETAILS_URL = `${RCB_CORE_ENDPOINT}plan`;
export const USAGE_DETAILS_URL = `${RCB_CORE_ENDPOINT}usage/consumption/`;
export const CHANGE_ADDONS_URL = `${RCB_CORE_ENDPOINT}addon`;

export const CLOUD_DEBUG_ENVIRONMENT_ROOT_URL = `${CATALOG_ROOT_URL}debugenv`;

export const ZOHO_DESK_ROOT_URL = `${CORE_ROOT_URL}api/zoho/proxy`;

export const { PLATFORM_VERSION } = CONFIG;
export const PLATFORM_UPDATES_URL = 'https://docs.rapyuta.io/updates/';

export const PAYMENT_SIGNATURE_URL = `${CONFIG.CORE_ENDPOINT}api/rcb/payment/signature/`;

export const { DASHBOARD_ENDPOINT, METRICS_ENDPOINT, WEBVIZ_ENDPOINT } = CONFIG;

export const UI_VERSION = '0.108.0';

export const sshStatusColors = {
  error: 'error',
  red: 'red',
  ok: 'ok',
  green: 'green',
  progress: 'progress',
};

export const sshFrontendStatuses = {
  connecting: 'Connecting',
  authenticating: 'Authenticating user',
  connection_error: 'Connection error',
  connection_timed_out: 'Connection timed out',
  connection_failed: 'Connection failed',
  reconnect_attempt: 'Reconnecting',
  reconnection_error: 'Error reconnecting',
  reconnection_failed: 'Failed to connect',
  timeout: 'Timed out',
  closed: 'Connection closed',
  connected: 'Connected',
  ssh_not_installed: 'sshd is either not installed or not running',
  'SSH CONNECTION ESTABLISHED': 'SSH Connection established',
  unauthorized_access: 'Unauthorized Access',
};

export const ejsConfig = {
  ripUrl: process.env.RIP_FRONTEND_ENDPOINT,
  pathParam: encodeURIComponent('/'),
  docsUrl: process.env.DOCS_ROOT_URL || 'https://docs.rapyuta.io/',
  pricingEmail: process.env.PRICING_EMAIL || 'pricing@rapyuta-robotics.com',
  MATOMO_ENDPOINT: process.env.MATOMO_ENDPOINT,
};
