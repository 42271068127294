import { REGISTRY_DOMAIN_MAP } from 'Root/additonal_resources/secrets/constants';

import { APIError } from '../common/types';

import { APISecret, CreateSecretAPIPayload, RegistryTypes, Secret, SecretFormData } from './types';

export const getErrorMessageFromResponse = (error: APIError): string =>
  error.response?.data.error || error.message;

export const mapAPISecretToSecret = ({
  metadata: { name, guid, creatorGUID, createdAt, updatedAt, projectGUID, labels },
  spec: { docker },
}: APISecret): Secret => ({
  name,
  guid,
  createdAt,
  updatedAt,
  creator: creatorGUID,
  projectGUID,
  labels,
  ...docker,
});

export const mapAPISecretListToSecretList = (secretList: APISecret[]): Secret[] =>
  secretList.map(mapAPISecretToSecret);

const getURLFromRegistryType = (
  registryType: RegistryTypes,
  customURL: string | undefined,
): string => {
  let url = '';
  const registry = Object.entries(RegistryTypes).find(([_, value]) => value === registryType);
  if (registry) {
    url = REGISTRY_DOMAIN_MAP[registry[0] as keyof typeof RegistryTypes];
  }
  if (url === '') {
    return customURL as string;
  }
  return url;
};

export const mapSecretToAPISecret = (formData: SecretFormData): CreateSecretAPIPayload => {
  const { name, username, password, email, url, registryType, labels } = formData;

  const payload: CreateSecretAPIPayload = {
    metadata: {
      name,
      labels: labels?.reduce((result, { key, value }) => ({ ...result, [key]: value ?? '' }), {}),
    },
    spec: {
      docker: {
        username,
        password,
        email,
        registry: getURLFromRegistryType(registryType, url),
      },
    },
  };

  return payload;
};
